<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
import { VueEditor } from "vue2-editor";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "New Employee Performance Development Plan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    VueEditor,
  },
  computed: {
    ...authComputed,
  },
  mounted() {

  },
  data() {
    return {
      title: "New Employee Performance Development Plan",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "New Employee Performance Development Plan",
          active: true,
        },
      ],
      texts: [
        { id: 0, goal: null },
        { id: 1, goal: null },
        { id: 2, goal: null },
      ],
      field:5,
    };
  },
  methods: {
    addField() {
      this.texts.push({ id: this.count++, goal: null });
      this.count++;
    },
    delField(index) {
      if (index > 0) {
        this.texts.splice(index, 1);
      }
    },
    async getSelfAssessment() {
      const url = `${this.ROUTES.selfAssessment}/get-self-assessments/${this.getEmployee.emp_id}`;
      await this.apiGet(url).then((res) => {
        const { data } = res;
        if (data.questions.length) {
          this.texts = [];
          this.gsID = data.openGoal[0].gs_id;
          this.assessStatus = data.questions[0].sa_status;
          data.questions.forEach(async (datum) => {
            this.selfAssessmentStatus = true;
            this.prefillStatus = true;
            const dat = {
              id: datum.sa_id,
              goal: datum.sa_comment,
              status: datum.sa_status,
            };
            this.texts.push(dat);
          });
        } else {
          this.newAssessment = true;
          this.texts = [
            { id: 0, goal: null },
            { id: 1, goal: null },
            { id: 2, goal: null },
          ];
        }
      });
    },
    submitPerformancePlan() {
      const employeeID = this.getEmployee.emp_id;
      let empProbation = this.getEmployee.emp_probation;
      const url = `${this.ROUTES.performanceImprovement}/add-performance-plan`;
      this.goals = [];
      let validForm = true;
      this.texts.every(async (field) => {
        if (field.goal === "" || !field.goal) {
          this.apiFormHandler("Invalid Goal Fields");
          validForm = false;
          return false;
        }
        const data = {
          goal: field.goal,
        };
        this.goals.push(data);
        return true;
      });
      if (validForm) {
        const data = {
          goals:this.goals,
          employee:employeeID,
          type:parseInt(empProbation) === 0 ? 2 : 1,
        }
        this.apiPost(url, data, "Add goals Error").then(() => {
          this.apiResponseHandler("Process Complete", "Goals Added");
          this.$router.push({
            name: "performance-development-plan"
          });


        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button
        class="btn btn-success"
        @click="$router.push({ name: 'performance-development-plan' })"
      >
        <i class="mdi mdi-view-list mr-2"></i>
        Performance Dev. Plans
      </b-button>
    </div>
    <scale-loader v-if="apiBusy" />
    <div class="row">
      <div
        class="col-lg-12"
      >
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-14 mb-0">Performance Development Form</h5>
            </div>
            <div class="mb-3">

              <p class="mt-3">
                Work with your manager to discuss and agree on at least
                <strong>3</strong> goals for your work over this review period.
              </p>
              <p>
                Try to write at least 2-3 performance goals, focused on your key
                areas of responsibility. Then, write 1 goal about an area of
                growth/development. To create a development goal, consider a
                strength you would like to get even better in, or a challenging
                area you wish to improve in.
              </p>
              <p>
                Your goals should be SMART (specific, measurable, actionable,
                realistic, timebound). For tips on creating SMART goals,
                <a
                  href="https://rescue.box.com/s/v5a0wfucoe1drhrz2cepy6wzr6urr3hr"
                  target="_blank"
                >
                  click here.
                </a>
                For an e-learning module on how to write a good goal,
                <a
                  href="https://kayaconnect.org/auth/saml2/login.php?wants=https://kayaconnect.org/course/info.php?id=1276&idp=dbcf769ab2c00b042dd2189fca3b115f&passive=off"
                  target="_blank"
                >
                  click here.
                </a>
              </p>
            </div>
            <form  @submit.prevent="submitPerformancePlan">
              <div class="row" v-for="(field, index) in texts" :key="index">
                <div class="col mb-5">
                  <div class="form-group">
                    <label for="goal">
                      Goal {{ index + 1 }} <span class="text-danger">* </span>
                    </label>
                    <vue-editor style="height: 200px; border: none;" v-model="field.goal"
                                class="form-control"></vue-editor>
                  </div>
                </div>
                <div class="col-1" v-if="index > 2">
                  <div class="form-group">
                    <label style="visibility: hidden">hidden</label><br />
                    <button
                      type="button"
                      class="btn btn-danger btn-block"
                      @click="delField(index)"
                    >
                      DEL
                    </button>
                  </div>
                </div>
              </div>

              <div >
                <div class="alert alert-info d-flex mt-3">
                  <i class="ri-error-warning-line mr-2"></i>
                  You must submit a minimum of 3 goals.
                  <span style="cursor: pointer" class="ml-1" @click="addField">
                    Click here to add a new goal
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-button
                      v-if="!submitting"
                      class="btn btn-success btn-block mt-4"
                      type="submit"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-else
                      disabled
                      class="btn btn-success btn-block mt-4"
                      type="submit"
                    >
                      Submitting...
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
